import React, { useState, useEffect,useCallback } from 'react';
import { StyleSheet,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView } from 'react-native';
import Modal from 'react-native-modal';

import { firebase } from '../config/firebase'
import colours from '../config/colours';
import { useNavigation } from '@react-navigation/native';
import MadeLogo from '../assets/images/made.svg';
import Menuicon from '../assets/images/menu.svg';
import "typeface-muli";

import AccordionListItem from './mainMenu'
const { height, width } = Dimensions.get('window')

const Header = () => {
  const [open, setOpen] = useState(false);



  const toggleModal = () => {
    setOpen(!open);
  };

    const goHome = () => {
      setOpen(!open);
      //navigation.goBack();
    };
    const Home =() => {
      navigation.navigate('HOME');
    }

    const menuHome =() => {
      navigation.navigate('HOME');
      setOpen(!open);
    }
    const menuAbout =() => {
      navigation.navigate('ABOUT US');
      setOpen(!open);
    }
    const menuServices =() => {
      navigation.navigate('Services List')

      setOpen(!open);
    }
    const menuProjects =() => {
     navigation.navigate('Projects');
      setOpen(!open);
    }
    const menuContact =() => {
      navigation.navigate('CONTACT');
      setOpen(!open);
    }
    const navigation = useNavigation();
    return (
      <SafeAreaView>
      <View style={styles.safeView}>


                <View style={styles.menuContainer}>
                <AccordionListItem title={
                                  <View style={styles.logoContainer}>
                  
                                        <TouchableOpacity 
                                        onPress={() => Home()} 
                                        >
                                            <img src={MadeLogo} width={100} />
                                            <Text style={styles.logoSubTitleText}>From Design To Reality</Text>
                                      </TouchableOpacity>
              
                                  </View>
                }>

<View style={styles.menuButtonRowContainer}>



                        <TouchableOpacity
                          onPress={() => menuHome()}
                        >
                          <View style={styles.menuButtonTextContainer}>
                            <Text style={styles.menuButtonText}>Home</Text>
                          </View>
                        </TouchableOpacity>





                        <TouchableOpacity
                          onPress={() => menuAbout()}
                        >
                          <View style={styles.menuButtonTextContainer}>
                            <Text style={styles.menuButtonText}>About Us</Text>
                          </View>
                        </TouchableOpacity>

                        <TouchableOpacity
                          onPress={() => menuServices()}

                        >
                          <View style={styles.menuButtonTextContainer}>
                            <Text style={styles.menuButtonText}>Services</Text>
                          </View>
                        </TouchableOpacity>

                        <TouchableOpacity
                          //onPress={() => toggleModal() }
                          onPress={() => menuProjects()}

                        >
                          <View style={styles.menuButtonTextContainer}>
                            <Text style={styles.menuButtonText}>Projects</Text>
                          </View>
                        </TouchableOpacity>

                        <TouchableOpacity
                          onPress={() => menuContact()}
                        >
                          <View style={styles.menuButtonTextContainer}>
                            <Text style={styles.menuButtonText}>Contact Us</Text>
                          </View>
                        </TouchableOpacity>
        </View>
               
               
               
               
               
                </AccordionListItem>


                
              </View>


      </View>
      </SafeAreaView>

    );
  };



const styles = StyleSheet.create({
  safeView: {
    flex: 1,
    backgroundColor: colours.primaryBlack,
  },
  menuContainer: {
    flex:1,
    paddingHorizontal:7,
    paddingTop:3,
  },

  logoContainer: {
  
   paddingStart:width >1000?2:5,
   paddingTop:width >1000?10:4,
  },

  logoSubTitleText:{
    paddingLeft:2,
    color:colours.fifthGrey,
    fontSize:'.56rem',
    fontWeight:'500',
    fontFamily:'muli',
  },
  menuButtonRowContainer: {
    flexDirection:'row',
    flexWrap:width >1000?'nowrap':'wrap',
    alignSelf:'flex-end'
  },
  menuButtonTextContainer: {
    backgroundColor: colours.secondaryBlack,
    marginVertical:5,
    marginHorizontal:width >1000?10:3,
    paddingHorizontal:width >1000?20:7,
    paddingVertical:width >1000?10:7,
    borderRadius:5,
  },

  menuButtonText: {
    fontSize: width >1000? '1rem' :'.9rem',
    fontWeight:'400',
    fontFamily:'muli',
    color: colours.brandColorDark,
    letterSpacing:.5
  },

});
  

export default Header;