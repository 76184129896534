import React, { useState, useEffect,useCallback } from 'react';
import { StyleSheet,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView , Linking} from 'react-native';
import Modal from 'react-native-modal';

import Contact from '../components/contact'
import colours from '../config/colours';
import { useNavigation } from '@react-navigation/native';

import Facebook from '../assets/social/Facebook.svg';
import WhatsApp from '../assets/social/Whatsapp.svg';
import Instagram from '../assets/social/Instagram.svg';
import LinkedIn from '../assets/social/Linkedin.svg';
import Twitter from '../assets/social/Twitter.svg';
import Youtube from '../assets/social/Youtube.svg';

import Email from '../assets/social/Email.svg';
import Velocitia from '../assets/images/velocitia.svg'

import "typeface-muli";


const { height, width } = Dimensions.get('window')

if(height<width){
  var widthNew=height;
  var heightNew=width;
} else{
  var widthNew=width;
  var heightNew=height;
}


const Footer = () => {
  const [isModalVisibleContact, setModalVisibleContact] = useState(false);

  const toggleModalContact = () => {
    setModalVisibleContact(!isModalVisibleContact);
  };
    
    const goHomeContact = () => {
      setModalVisibleContact(!isModalVisibleContact);
    };

    const navigation = useNavigation();
    const whatsappURL = 'https://api.whatsapp.com/send?phone=971503773805'

    return (
      <SafeAreaView>
      <View style={styles.safeView}>
      <View style={styles.responsiveWidth}>
              <View style={styles.contactContainer}>

                <View style={styles.contactTitle}>
                    <Text numberOfLines={1} style={styles.categoryTitleText} >
                     Let's work together   
                    </Text>
                </View>

                <View style={styles.contactButton}>
                <TouchableOpacity onPress={() => toggleModalContact() }>
                    <Text numberOfLines={1} style={styles.contactButtonText} >
                      Contact Us 
                    </Text>
                </TouchableOpacity>
                </View>

              </View>
              
            </View>



            <View style={styles.yearBoxContainer}>
            <View style={styles.categoryTitle}>
                    <Text numberOfLines={1} style={styles.yearBoxTextHeader} >
                      Connect with Us
                    </Text>
                </View>


                <View style={styles.yearBoxRow}>

                <TouchableOpacity 
                 onPress={() => Linking.openURL(whatsappURL)}
                 >
                <View style={styles.socialBox}>
                  <img src={WhatsApp} width={35} />
                </View>
                </TouchableOpacity>


              <TouchableOpacity 
              onPress={() => Linking.openURL('https://www.facebook.com/madeinteriorsuae')}

                    >
                <View style={styles.socialBox}>
                  <img src={Facebook} width={35} />

                </View>
                </TouchableOpacity>



                <TouchableOpacity 
              onPress={() => Linking.openURL('https://www.instagram.com/madeinteriorsuae')}


                    >
                <View style={styles.socialBox}>
                  <img src={Instagram} width={35} />

                </View>
                </TouchableOpacity>





                <TouchableOpacity 
              onPress={() => Linking.openURL('https://www.linkedin.com/company/madeinteriors')}


                    >
                <View style={styles.socialBox}>
                  <img src={LinkedIn} width={35} />

                </View>
                </TouchableOpacity>
                {/* <TouchableOpacity 
              //onPress={() => https://twitter.com/madeinteriorsuae
              onPress={() => Linking.openURL('https://twitter.com/madeinteriorsuae')}


                    >
                <View style={styles.socialBox}>
                  <img src={Twitter} width={35} />

                </View>
                </TouchableOpacity> */}

{/* 
                <TouchableOpacity 
              onPress={() => Linking.openURL('https://www.youtube.com/channel/UCHmH-3pQkOMul0Taoadq38g')}


                    >
                <View style={styles.socialBox}>
                  <img src={Youtube} width={35} />

                </View>
                </TouchableOpacity> */}

                
  

                <TouchableOpacity 
                onPress={() => Linking.openURL('mailto:info@pdc-interiors.com?subject=Support&body=Hi')}

                    >
                <View style={styles.socialBox}>
                  <img src={Email} width={35} />
                </View>
                </TouchableOpacity>

                </View>


              </View>

            <View style={styles.footer}>
                    <Text numberOfLines={1} style={styles.footerText} >
                        © Copyright {new Date().getFullYear()} | All rights reserved  | {' '}
                        <Text numberOfLines={1} style={styles.footerLink} >
                        <TouchableOpacity onPress={() => Linking.openURL('https://pdc-interiors.com/privacy')} > <Text>Privacy Policy </Text></TouchableOpacity>
                        </Text>
                        {' '}| {' '}
                        <Text numberOfLines={1} style={styles.footerLink} >
                        <TouchableOpacity onPress={() => Linking.openURL('https://pdc-interiors.com/termsconditions')} ><Text>Terms & Conditions</Text></TouchableOpacity>
                        </Text>

                        </Text>
                        <Text numberOfLines={1} style={styles.footerText} >
                        MADE INTERIORS | Memeber of <TouchableOpacity 
                onPress={() => Linking.openURL('https://pdcgroupconsulting.com')}
                    >
                  <Text style={styles.footerLink}>PDC Group</Text>
                </TouchableOpacity>
                    </Text>


                    <Text numberOfLines={1} style={styles.footerTextVelocitia} >
                        Web App by {'  '}
                        <TouchableOpacity 
                        onPress={() => Linking.openURL('https://velocitia.com')}
                        >                       
                        <img src={Velocitia} width={width>1000? 60:50} />
                          </TouchableOpacity>
                    </Text>



<Modal 
isVisible={isModalVisibleContact} 
transparent={true}
onBackdropPress={goHomeContact}
>
<View>
  <Contact
  toggleModal={toggleModalContact}
  />
</View>
</Modal>

      </View>
      </View>
      </SafeAreaView>

    );
  };



const styles = StyleSheet.create({
  safeView: {
    flex: 1,
  },

  responsiveWidth: {
    maxWidth:1000,
    alignContent: 'center',
    justifyContent: 'center',
  },

  contactContainer: {
    flexDirection: 'row',
    marginHorizontal: (widthNew / 25),
    marginVertical: 10,
    paddingHorizontal:20,
    paddingVertical:10,
    borderRadius:15,

    backgroundColor:colours.tertiaryBlack

  },
  contactButton: {
    //justifyContent: 'flex-end',
    borderWidth: 0.5,
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 10,
    backgroundColor: colours.brandColorExtraDark,
  },


  contactTitle: {
    flex: 4,
    justifyContent: 'center',
    paddingBottom:5,

  },


  categoryTitleText: {
    fontSize: '1rem',
    color: colours.primaryGrey,
    fontFamily:'muli',
    marginTop: 2,
  },


  contactButtonText: {
    fontSize: '.9rem',
    fontFamily:'muli',
    fontWeight:700,
    color: colours.brandColorLight
  },
  footer: {
    fontSize: 20,
    color: colours.primaryGrey,
    marginBottom: 10,
    fontFamily:'muli',


  },
  footerText: {
    fontSize: width>1000? '.8rem' :'.7rem',
    color: colours.brandColorDark,
    textAlign: 'center',
    fontWeight: '400',
    fontFamily:'muli',
    lineHeight:'1.5rem',

  },
  footerTextVelocitia: {
    fontSize: width>1000? '.8rem' :'.7rem',
    color: colours.primaryGrey,
    textAlign: 'center',
    fontWeight: '400',
    fontFamily:'muli',
    lineHeight:'1.7rem',

    marginBottom:7,

  },

  footerLink: {
    fontSize: width>1000? '.8rem' :'.7rem',
    color: colours.brandColor,
    textAlign: 'center',
    fontWeight: '700',
    fontFamily:'muli',
    lineHeight:'1.7rem',
  },




  yearBoxContainer:
  {
    //marginHorizontal: 10,
    marginVertical: 5,
    paddingVertical: 5,
  },
  yearBoxRow: {
    marginVertical: 5,
    height:55,
    flexDirection: 'row',
    justifyContent: 'center',

  },
  socialBox: {
    //flex: 4,
    //paddingHorizontal:15,
    justifyContent: 'center',
    marginHorizontal: 5,
    marginVertical: 5,
  },
  yearBoxText: {
    fontSize: 12,
    color: 'white',
    textAlign: 'center',
    fontWeight: '500'

  },
  yearBoxText2: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
    fontWeight: '500'

  },
  yearBoxTextHeader: {
    fontSize: 20,
    color: colours.thirdGrey,
    textAlign: 'center',
    fontWeight: '700',
    fontFamily:'muli',

  },



});
  

export default Footer;